import React, { useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ReceiptDetails from './ReceiptDetails';
import OpenAI from 'openai';

const apikey = "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";

const openai = new OpenAI({
  apiKey: apikey,
  dangerouslyAllowBrowser: true
});

const UploadImage = () => {
  const { partner } = useParams();
  const [receiptDetails, setReceiptDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const fileInputRef = useRef(null);

  const normalizeData = (data) => {
    const getValueByKeys = (obj, keys) => {
      for (let key of keys) {
        if (obj.hasOwnProperty(key)) {
          return obj[key];
        }
      }
      return undefined;
    };

    const normalizeOrderItems = (items) => {
      return items.map(item => ({
        item_name: getValueByKeys(item, ['item_name', 'itemName']),
        quantity: item.quantity,
        price: item.price
      }));
    };

    return {
      order_id: getValueByKeys(data, ['order_ID', 'orderID', 'order_id']),
      order_date: getValueByKeys(data, ['order_date', 'orderDate']),
      customer_name: getValueByKeys(data, ['customer_name', 'customerName']),
      customer_phone_number: getValueByKeys(data, ['customer_phone_number', 'customerPhoneNumber']),
      order_items: normalizeOrderItems(getValueByKeys(data, ['order_item_list','orderItemList', 'orderItems', 'order_items']) || []),
      subtotal_amount: getValueByKeys(data, ['subtotal_amount', 'subtotalAmount']),
      delivery_fees: getValueByKeys(data, ['delivery_fees', 'deliveryFees']),
      discount: data.discount,
      total: getValueByKeys(data, ['total', 'totalAmount'])
    };
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      await handleUploadAndProcess(file);
    }
  };

  const convertImageToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = error => reject(error);
    });
  };

  const processImageWithOpenAI = async (base64Image) => {
    try {
      console.log('Sending request to OpenAI...');
      const response = await openai.chat.completions.create({
        model: "gpt-4o-mini-2024-07-18",
        messages: [
          {
            role: "user",
            content: [
              {
                type: "text",
                text: "order ID, order date (Using ISO 8601 format) , customer name, customer Phone Number, order item list with item name, quantity and price, subtotal amount, delivery fees, discount, total, i need these value in json format"
              },
              {
                type: "image_url",
                image_url: {
                  url: `data:image/jpeg;base64,${base64Image}`
                },
              },
            ],
          }
        ],
        max_tokens: 500,
      });

      console.log('Received response from OpenAI:', response);

      const content = response.choices[0].message.content;
      console.log('Response content:', content);

      const jsonMatch = content.match(/```json\n([\s\S]*?)\n```/);
    
      if (jsonMatch) {
        console.log('Extracted JSON:', jsonMatch[1]);
        return JSON.parse(jsonMatch[1]);
      } else {
        throw new Error('Failed to extract JSON from OpenAI response');
      }
    } catch (error) {
      console.error('Error processing image with OpenAI:', error);
      throw error;
    }
  };

  const handleUploadAndProcess = async (file) => {
    setIsLoading(true);
    setErrorMessage('');

    try {
      console.log('Converting image to base64...');
      const base64Image = await convertImageToBase64(file);
      console.log('Image converted to base64');

      console.log('Processing image with OpenAI...');
      const data = await processImageWithOpenAI(base64Image);
      console.log("Raw API response:", data);

      if (!data || typeof data !== 'object') {
        throw new Error('Invalid data received from OpenAI');
      }

      const normalizedData = normalizeData(data);
      console.log("Normalized data:", normalizedData);

      setReceiptDetails(normalizedData);
    } catch (error) {
      console.error('Error processing image:', error);
      setErrorMessage(`Error processing image: ${error.message}`);
      alert(`Error processing image: ${error.message}. Please try again.`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleApprove = useCallback(async (orderData) => {
    try {
      const response = await axios.post('https://api.aijazai.online/orders/approve_order', {
        ...orderData,
        delivery_partner: partner,
      });
      
      if (response.status === 201) {
        alert('Order approved and saved successfully!');
        setReceiptDetails(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    } catch (error) {
      console.error('Error approving order:', error);
      alert(`Error approving order: ${error.message}`);
    }
  }, [partner]);

  const handleEdit = useCallback((editedDetails) => {
    setReceiptDetails(editedDetails);
  }, []);

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Upload {partner} Receipt Image</h2>
      <div className="mb-4">
        <input 
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept="image/*"
          capture="environment"
          className="hidden"
        />
        <button 
          onClick={triggerFileInput}
          className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
        >
          Choose Image or Take Photo
        </button>
      </div>
      {isLoading && <p>Processing image...</p>}
      {errorMessage && <p className="text-red-500">{errorMessage}</p>}
      {receiptDetails && (
        <ReceiptDetails
          details={receiptDetails}
          partner={partner}
          onApprove={handleApprove}
          onEdit={handleEdit}
        />
      )}
    </div>
  );
};

export default UploadImage;
